<template>
    <div class="vue-box">
        <div class="c-panel">
            <!-- 参数栏 -->
            <el-form :inline="true" size="mini">
               
              
                <el-form-item label="任务名称：">
                    <el-input v-model="p.name" placeholder="模糊查询"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <input-enum enumName="TaskStateEnum" v-model="p.state"></input-enum>
                </el-form-item>
                <el-form-item style="min-width: 0px">
                    <el-button type="primary" icon="el-icon-search" @click="f5();">查询 </el-button>
                    <el-button type="primary" icon="el-icon-plus" @click="add">增加</el-button>
                </el-form-item>
            </el-form>
            <!-- <div class="c-title">数据列表</div> -->
            <el-table :data="dataList" size="mini">
                <el-table-column label="序号" prop="id"></el-table-column>
                <el-table-column label="所属产品" prop="productName"></el-table-column>
                <el-table-column label="研发类型" prop="productTypeEnum"></el-table-column>
                <el-table-column label="任务名称" prop="name"></el-table-column>
                <el-table-column label="指派用户" prop="users"></el-table-column>
                <el-table-column label="状态" prop="stateEnum"></el-table-column>
                <el-table-column label="计划完成时间" prop="planDate"></el-table-column>
                <el-table-column label="实际完成时间" prop="finishDate"></el-table-column>
                <el-table-column label="任务描述"  min-width="120" :show-overflow-tooltip="true" prop="content"></el-table-column>
                <el-table-column prop="address" label="操作" width="220px">
                    <template slot-scope="s">
                        <el-button class="c-btn" type="primary" icon="el-icon-edit" @click="update(s.row)">修改
                        </el-button>
                        <el-button class="c-btn" type="danger" icon="el-icon-delete" @click="del(s.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="page-box">
                <el-pagination background layout="total, prev, pager, next, sizes, jumper" :current-page.sync="p.page"
                    :page-size.sync="p.pageSize" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]"
                    @current-change="f5(true)" @size-change="f5(true)">
                </el-pagination>
            </div>
        </div>

        <!-- 增改组件 -->
        <add-or-update ref="new-or-update"></add-or-update>
    </div>
</template>

<script>
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
import addOrUpdate from './new.vue';
export default {
    components: {
        addOrUpdate,
        inputEnum
    },
    data() {
        return {
            p: { pageSize: 10, page: 1, category: 2, name: '', state: '' },
            dataCount: 0,
            dataList: [],
        }
    },
    methods: {
        // 数据刷新
        f5: function () {
            this.sa.put("/task/listPage", this.p).then(res => {
                this.dataList = res.data.content.map((item) => {

                    return item;
                });;
                this.dataCount = res.data.totalElements;
            });
        },
        // 删除
        del: function (data) {
            this.sa.confirm('是否删除，此操作不可撤销', function () {
                this.sa.delete("/task/delete/" + data.id).then(res => {
                    console.log(res)
                    this.sa.arrayDelete(this.dataList, data);
                    this.sa.ok(res.message);
                });

            }.bind(this));
        },
        //更新
        update(row) {
            this.$refs['new-or-update'].open(row);
        },
        view(row){
            this.sa_admin.n
        },
        //添加
        add: function () {
            this.$refs['new-or-update'].open({category:2});
        }
    },
    created: function () {
        this.f5();
    }
}

</script>

<style>
</style>
